@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  font-family: 'Manrope', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.full-page-width {
  width: 100%;
  overflow: hidden;
}

.Wrapper {
  max-width: 1650px;
  margin-left: auto;
  margin-right: auto;
}

/* navbar */

.header {
  display: flex;
  padding: 40px 0;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 40px;
}

.header button {
  color: #10abb7;
  border-color: #10abb7;
  border-radius: 8px;
}

.header button:hover {
  color: white;
  background-color: #10abb7;
  border-color: #10abb7;
}

.logo {
  margin-right: auto;
}

.logo img {
  width: auto;
  height: 60px;
}

.nav-item-container {
  margin-left: auto;
  display: flex;
  gap: 50px;
  align-items: center;
  color: #505050;
  font-weight: 400;
}

.nav-item:hover {
  color: #10abb7;
}

/* introduction */

.introduction {
  display: flex;
}

.introduction-Heading {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 30px;
}

.introduction-SubHeading {
  font-size: 1.2rem;
  font-weight: 400;
  width: 515px;
  color: #505050;
  margin-bottom: 40px;
}

.introduction-Heading span {
  color: #10abb7;
}

.introduction-text button {
  background-color: #10abb7;
  width: 311px;
  height: 56px;
  padding: 8px;
  box-shadow: 4px 4px 10px rgba(192, 186, 186, 0.25);
  border-radius: 8px;
  font-weight: bold;
  font-size: 20px;
}

.introduction-text button:hover {
  background-color: #10acb7c6;
}

.introduction-partners {
  display: flex;
  align-items: center;
  gap: 28px;
  margin-top: 40px;
}

.introduction-partners img {
  width: auto;
  height: 100%;
}

.introduction-image {
  margin-left: auto;
}

/* flowchart */

.flowchart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 22px;
  width: 100%;
  height: 76px;
  margin-top: 130px;
  margin-bottom: 130px;
}

.flowchart-item {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 15px;
  width: 228px;
  height: 76px;
  background: #ffffff;
  border-radius: 16px;
}

.flowchart-item-text {
  width: 145px;
  font-weight: 600;
  font-size: 1.1rem;
  color: #505050;
}

.flowchart hr {
  width: 100px;
  border-top: 1px solid #c7c7c7;
}

/* body1 */

.body1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 64px;
  width: 100%;
}

.body1-heading {
  font-weight: 800;
  font-size: 2.1rem;
}

.body1-heading span {
  color: #10abb7;
}

.body1-subheading {
  font-size: 1.2rem;
  font-weight: 400;
  width: 742px;
  color: #505050;
  text-align: center;
}

.body1-flexbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px;
  gap: 60px;
  width: 100%;
}

.body1-flexbox-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

.body1-flexbox-item-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  width: 275px;
  height: 108px;
}

.body1-flexbox-item-text-heading {
  font-weight: 700;
  font-size: 1.1rem;
  color: #171717;
}

.body1-flexbox-item-text-subheading {
  font-weight: 400;
}

.body1 hr {
  width: 652px;
  height: 0px;
  border: 1.5px solid #e9e9e9;
  margin: 0px 0 80px;
}

/* card */

.card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 20px;
  gap: 12px;
  marginleft: 30px;
  marginright: 30px;
  height: 530px;
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 12.9571px;
  color: #505050;
  font-size: 0.9rem;
  font-weight: 400;
}

.card:hover {
  border: 2px solid #6683e8;
}

.card-heading {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #505050;
}

.card-domain {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.domain {
  padding: 6px 16px;
  border: 1px solid #10abb7;
  border-radius: 20px;
  color: #10abb7;
  font-weight: 600;
}

.card-skills {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: top;
}

.card-skills-text {
  margin-right: auto;
}

.card-skills-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 250px;
}

.skill {
  padding: 8px 10px;
  font-size: 0.8rem;
  border-radius: 20px;
  background-color: #9fdde2;
  font-weight: 400;
}

.card-company {
  font-size: 0.8rem;
}

.card hr {
  border: 1px solid #c3c3c3;
  height: 0px;
  width: 98%;
  margin: 10px auto;
}

.card-footer button {
  background-color: #10abb7;
}

.card-footer-text {
  display: flex;
  flex-direction: column;
  width: 230px;
  gap: 8px;
}

.card-footer-text-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

/* body2 */

.body2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 260px;
}

.body2-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 18px;
  width: 324px;
  height: 390px;
}

.body2-text-heading {
  font-size: 1.9rem;
  font-weight: 800;
}

.body2-text-subheading {
  color: #505050;
}

.body2-text-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.body2-text-buttons button {
  width: 212px;
  height: 36px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 8px 4px 16px;
  gap: 1px;
  border: 1px solid #10abb7;
}

.body2-text-buttons button:hover {
  background-color: #6683e8;
  box-shadow: 0px 8px 15px rgba(60, 60, 60, 0.2);
}

.body2-cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 24px;
}

.body2-seprator {
  width: 652px;
  height: 0px;
  border: 1.5px solid #e9e9e9;
  margin: 80px auto 80px;
}

/* body3 */

.body3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  gap: 250px;
}

.body3-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 417px;
  height: 136px;
}

.body3-text-heading {
  width: 311px;
  height: 48px;
  font-weight: 800;
  font-size: 1.9rem;
}

.body3-text-subheading {
  width: 423px;
  height: 56px;
  font-weight: 400;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #505050;
}

/* body4 */

.body4 {
  margin: 100px 0;
}

.body4-heading {
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 50px;
}

.body4-heading span {
  color: #10abb7;
}

.carousel-container {
  text-align: center;
}

.react-multi-carousel-dot--active button {
  background: #10adb9 !important;
}

.carousel-container-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 80px;
  margin: auto;
  width: 1000px;
  box-shadow: 7px 7px 50px rgba(65, 166, 160, 0.15);
}

.carousel-card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 16px;
  width: 521px;
}

.carousel-card-text-subheading {
  width: 505px;
  font-size: 1.1rem;
  font-weight: 400;
  display: flex;
  text-align: left;
  align-items: center;
  color: #171717;
}

.carousel-card-text-salutation {
  text-align: left;
}

.carousel-card-text-salutation-name {
  font-size: 1.8rem;
  font-weight: 700;
}

.carousel-card-text-salutation-post {
  color: #767676;
  font-size: 1rem;
  font-weight: 400;
}

/* body5 */

.body5 {
  /* margin: 100px 0; */
  margin: 100px -200%;
  overflow: hidden;
  width: 500%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px;
  gap: 42px;
  /* text-align: center; */
  background-color: #e8f5ff;
}

.body5-heading {
  font-size: 2rem;
  text-align: center;
  font-weight: 800;
}

.body5-heading span {
  color: #10abb7;
}

.body5-content-wrapper {
  width: 1340px;
  /* height: 550px; */
  padding: 50px;
  background-color: white;
  box-shadow: 0px 8px 15px rgba(158, 158, 158, 0.2);
  border-radius: 16px;
}

.body5-content-inner {
  /* width: 1200px; */
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 220px;
  margin: 100px auto;
}

.body5-content-inner img {
  width: 580px;
  height: 400px;
}

.body5-content-inner-buttons {
  display: flex;
  flex-direction: column;

  width: 300px;
  /* height: 300px; */
  gap: 60px;
  text-align: left;
}

.body5-content-inner-buttons button {
  text-align: left;
  color: #b0b0b0;
}

.active {
  font-size: 1.3rem;
  color: #171717 !important;
  font-weight: 700;
}

/* body6 */

.body6 {
  margin: 100px 0;
}

.body6-flexbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  gap: 250px;
}

.body6-heading {
  font-size: 2rem;
  text-align: center;
  font-weight: 800;
}

.body6-heading span {
  color: #10abb7;
}

.body6-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 50px;
  width: 417px;
  height: 136px;
}

.body6-text-heading {
  width: 311px;
  height: 48px;
  font-weight: 800;
  font-size: 1.9rem;
}

.body6-text-subheading {
  width: 423px;
  height: 56px;
  font-weight: 400;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #505050;
}

.body6 button {
  background-color: #10abb7;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 12px 22px;
}

/* body7 */

.body7 {
  margin: 100px 0;
}

.body7-heading {
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 50px;
}

.body7-heading span {
  color: #10abb7;
}

.body7-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0px;
  gap: 16px;
}

.body7-content-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}

.body7-content-inner-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  font-size: 1.2rem;
  color: #505050;
  /* width: 379px; */
}

/* popup */

.popup-content {
  margin: auto;
  width: auto !important;
  background: rgb(255, 255, 255);
  padding: 0 !important;
  font-size: 0.9rem;
  border: 0.5px solid #e9e9e9;
  box-shadow: 0px 8px 15px rgba(158, 158, 158, 0.2);
  border-radius: 24px;
}
.popup-arrow {
  display: none;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'] .popup-overlay {
  background: transparent;
}

.popup-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 32px;
  gap: 8px;
  height: 56px;
  background: #d3738a;
  border-radius: 24px 24px 0px 0px;
  color: white;
  font-weight: 600;
  font-size: 1rem;
}

.popup-heading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 32px;
  padding: 25px;
}

.popup-body-subheading1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 80px;
}

.popup-heading-tagline {
  color: #505050;
  font-size: 1.3rem;
  font-weight: 700;
  width: 500px;
}

.popup-heading a {
  margin-left: auto;
}

.popup-heading button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  box-shadow: none;
  color: #767676;
  border: 1px solid #767676;
  border-radius: 4px;
  font-weight: 400;
  margin-left: auto;
  padding: 5px 10px;
}

.popup-features {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  padding: 25px;
  width: 687px;
  height: 40px;
  color: #505050;
}

.popup-feature-item {
  display: flex;
  flex-direction: row;
  gap: 7px;
}

.popup-body-heading {
  font-size: 1.1rem;
  font-weight: 500;
  color: #505050;
}

.popup-body1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  height: 86px;
  margin-bottom: 40px;
  padding: 25px;
  color: #767676;
}

.popup-body2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  height: 86px;
  padding: 25px;
  margin-bottom: 30px;
  color: #767676;
}

.popup-body3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 90px;
  padding: 25px;
  color: #767676;
}

.popup-body3-item-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}

.section1,
.section2 {
  width: 350px;
}

.popup-body-hr {
  width: 41px;
  height: 0px;
  border: 1px solid #d3738a;
}

.popup-body3-item-section-text {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* body8 */

.body8 {
  margin: 100px 0;
  position: relative;
}

.body8-heading {
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 50px;
}

.body8-heading span {
  color: #10abb7;
}

.body8-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 250px;
  align-items: flex-start;
  text-align: center;
}

.milestoneCard {
  width: 330px;
  text-align: left;
}

.milestoneCard-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 1rem;
  color: #505050;
  margin-left: 15px;
}

.milestoneCard-list-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.dashed-line {
  position: absolute;
  z-index: -1;
  top: 35%;
}

/* body9 */

.body9 {
  margin: 100px 0;
}

.body9-heading {
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 50px;
}

.body9-heading span {
  color: #10abb7;
}

.body9-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0px;
  gap: 89px;

  width: 987px;
}

.journeyCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;

  /* width: 463px; */
  /* height: 342px; */

  background: #ffffff;

  /* Teal Tint */
  border: 1px solid #cff4f2;
  box-shadow: 4px 4px 10px rgba(150, 149, 149, 0.1);
  border-radius: 8px;
}

.journeyCard-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.journeyCard-top-salutation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.journeyCard-top-salutation-name {
  font-size: 1.3rem;
  font-weight: 700;
}

.journeyCard-top-salutation-post {
  font-size: 0.9rem;
  color: #767676;
}

.journeyCard-bottom {
  font-size: 0.9rem;
  line-height: 22px;
  font-weight: 400;
}

/* body10 */

.body10 {
  margin: 100px -200%;
  overflow: hidden;
  width: 500%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px;
  gap: 42px;
  text-align: center;
  background: linear-gradient(97.78deg, #e4eeff 1.74%, #fffcec 100%);
}

.body10-heading {
  font-size: 2.2rem;
  font-weight: 800;
}

.body10-heading span {
  color: #10abb7;
}

.body10-text {
  font-size: 1.2rem;
  color: #505050;
  width: 900px;
}

.body10 button {
  width: 300px;
  box-shadow: 4px 4px 10px rgba(192, 186, 186, 0.25);
  border-radius: 8px;

  margin-bottom: 30px;
  height: 56px;
  background-color: #10abb7;
  font-size: 1.1rem;
}

/* footer */

.footer {
  margin: 0px -200%;
  overflow: hidden;
  width: 500%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 70px;
  gap: 250px;
  color: #767676;
  background-color: #f2f2f2;
}

.footer-section1 {
  margin-left: -450px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 0px;
  gap: 32px;
  width: 382px;
}

.footer-section1-socials {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.footer-logo {
  width: 184px;
  height: auto;
}

.footer-section1-terms {
  font-size: 1rem;
}

.footer-section2-heading {
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-weight: 500;
}

.footer-section2 ul > li {
  text-align: left;
  margin: 5px 0;
  padding: 5px 5px;
  font-size: 0.9rem;
}

/* animation */
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
